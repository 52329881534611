import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';

import * as _ from 'lodash';

export class ScanEventTag extends AbstractApiModel<ScanEventTag> {
  skuString: string;
  clientSkuString: string;
  candidateSkus: any[];
  gtins: string[];
  price: string;
  salePrice: string;
  barcodes: any;
  name: string;
  shelfZ: number;
  tagRect: number[];
  imageUrl: string;
  imageStatus: string;
  saleImageUrl: string;
  saleImageStatus: string;
  skuName: string;
  topStockAvailable = false; //TODO: update backend to return a valid value

  scanEventId: number;
  skuId: number;
  gtinString: string;

  deserialize(json: any): this {
    this.skuString = json.sku_string;
    this.clientSkuString = _.isEmpty(json.client_sku) ? this.skuString : json.client_sku;
    this.candidateSkus = json.candidate_skus;
    this.gtins = json.gtins;
    this.price = json.price;
    this.salePrice = json.sale_price;
    this.barcodes = json.barcodes;
    this.skuName = _.isEmpty(json.sku_name) ? 'UNKNOWN' : json.sku_name;
    this.name = (_.isEmpty(json.name) || json.name === 'Unknown') ? this.skuName : json.name;
    this.shelfZ = json.shelf_z;
    this.tagRect = json.tag_rect;
    this.imageUrl = json.image_url;
    this.imageStatus = json.image_status || 'realized';
    this.saleImageUrl = json.sale_image_url;
    this.saleImageStatus = json.sale_image_status || 'realized';
    this.scanEventId = json.scan_event_id;
    this.skuId = json.sku_id;
    this.gtinString = json.gtins.toString();

    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      name: this.name
    });
  }

  has_high_confidence_barcode(): boolean {
    if (_.isEmpty(this.barcodes)) {
      return false;
    }
    return this.barcodes.some(barcode => barcode.score == 1.0);
  }
}

