import { MapGrouping } from './map-grouping';
import { MapLayer } from './map-layer';
import { TopStockObject } from '@ng-cloud/badger-core/map/objects/top-stock-object';
import { TopStock } from '@ng-cloud/badger-core/models/top-stock';

export class TopStockGrouping extends MapGrouping<TopStock, TopStockObject> {
  constructor(public options: any = {}, public layerName: string = 'topstock', public zIndex: number = 120) {
    super(options);

    this.layers = [new MapLayer(Object.assign({}, { name: layerName, zIndex: zIndex }, { grouping: this }))];
  }

  createObject(topStock: TopStock): TopStockObject {
    const points = topStock.shelfLocation.map(pt => this.storeMap.pointToPixels(pt));
    return new TopStockObject(topStock, points, this.options);
  }
}
