<div fxLayoutGap="10px" fxLayout="column" class="search-dialog" (click)="$event.stopPropagation()">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
    <mat-tab label="Play Execution">
      <form *ngIf="playExecutionForm" [formGroup]="playExecutionIdFormGroup" (ngSubmit)="searchByPlayExecutionId()">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="search-controls">
          <bt-form-field fxFlex="300px">
            <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
              <mat-label>Play Execution ID</mat-label>
              <input type="number" formControlName="id" matInput/>
            </mat-form-field>
          </bt-form-field>
          <button mat-flat-button color="primary" type="submit" [disabled]="playExecutionIdFormGroup.value?.id == null">Search</button>
          <span *ngIf="playExecutionNoResults" class="no-results">No results found.</span>
        </div>
      </form>
      <mat-table #table [dataSource]="playExecutionDataTable">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
          <mat-cell *matCellDef="let playExecution">
            <a
              routerLink="/stores/{{playExecution.storeId}}/{{playExecution?.jobType == 'insight' ? 'insight' : 'inspect'}}/play-executions/{{playExecution.id}}"
              class="clickable">{{playExecution.id}} </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="play">
          <mat-header-cell *matHeaderCellDef> Play</mat-header-cell>
          <mat-cell *matCellDef="let playExecution">
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxLayoutAlign="center center">
                <mat-icon color="primary" [svgIcon]="playExecution?.jobType == 'insight' ? 'insight_marty' : 'inspect_marty'"></mat-icon>
              </div>
              <div fxLayout="column">
                <a
                  routerLink="/stores/{{playExecution.storeId}}/{{playExecution?.jobType == 'insight' ? 'insight' : 'inspect'}}/play-executions/{{playExecution.id}}"
                  class="clickable">{{playExecution.play?.name}} </a>
                <div matTooltip="{{getMainDateTimeString(playExecution.startTime, playExecution.store)}}"
                     class="search-result-subtext">{{playExecution.startTime | date:'short'}}</div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="store">
          <mat-header-cell *matHeaderCellDef> Store</mat-header-cell>
          <mat-cell *matCellDef="let playExecution">
            <div fxLayout="column">
              <a routerLink="/stores/{{playExecution.storeId}}" class="clickable">{{playExecution.store?.name}} </a>
              <div class="search-result-subtext">{{playExecution.robot?.name}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef> Details</mat-header-cell>
          <mat-cell *matCellDef="let playExecution">
            <div *ngIf="playExecution.jobType == 'insight'; else inspectDiv" fxLayoutGap="5px" fxLayoutAlign="start center">
              <mat-icon
                [ngClass]="playExecution.playOptions?.play_outputs?.includes('hole_oos') ? 'hole_oos' : 'issue-disabled'"
                [svgIcon]="'hole_oos_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="playExecution.playOptions?.play_outputs?.includes('eOOS') ? 'eOOS' : 'issue-disabled'"
                [svgIcon]="'eOOS_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="playExecution.playOptions?.play_outputs?.includes('price_mismatch') ? 'price_mismatch' : 'issue-disabled'"
                [svgIcon]="'price_mismatch_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="playExecution.playOptions?.play_outputs?.includes('sale_price_mismatch') ? 'sale_price_mismatch' : 'issue-disabled'"
                [svgIcon]="'sale_price_mismatch_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="playExecution.playOptions?.play_outputs?.includes('top_stock') ? 'top_stock' : 'issue-disabled'"
                [svgIcon]="'top_stock_alt'">
              </mat-icon>
            </div>
            <ng-template #inspectDiv>
              <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
                <div fxLayout="column">
                  <div>Hazards: {{playExecution.hazardCount}}</div>
                  <div>Urgents: {{playExecution.urgentCount}}</div>
                </div>
                <div fxLayoutAlign="start center">
                  <button *ngIf="!idsGenerating.includes(playExecution.id); else loading"
                          [ngClass]="playExecution.urgentCount == 0 ? 'hidden' : ''" mat-icon-button
                          matTooltip="Download Urgent Report" (click)="downloadUrgentReport(playExecution.id)">
                    <mat-icon color="primary">file_download</mat-icon>
                  </button>
                  <ng-template #loading>
                    <mat-spinner class="urgent-spinner" [diameter]="20"></mat-spinner>
                  </ng-template>
                </div>
              </div>
            </ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="playExecutionDataTable?.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: playExecutionDataTable?.displayedColumns"></mat-row>
      </mat-table>
    </mat-tab>

    <mat-tab label="Scan Event">
      <form *ngIf="scanEventForm" [formGroup]="scanEventIdFormGroup" (ngSubmit)="searchByScanEventId()">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="search-controls">
          <bt-form-field fxFlex="300px">
            <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
              <mat-label>Scan Event ID</mat-label>
              <input min="0" type="number" formControlName="scan_event_id"
                     matInput/>
            </mat-form-field>
          </bt-form-field>
          <button mat-flat-button color="primary" type="submit" [disabled]="scanEventIdFormGroup.value?.scan_event_id == null">Search</button>
          <span *ngIf="scanEventNoResults" class="no-results">No results found.</span>
        </div>
      </form>
      <mat-table #table [dataSource]="scanEventDataTable">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <a
              routerLink="/stores/{{data.playExecution?.storeId}}/insight/play-executions/{{data.playExecution?.id}}/scan-events/{{data.scanEventId}}"
              class="clickable">{{data.scanEventId}} </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="store">
          <mat-header-cell *matHeaderCellDef> Store</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div fxLayout="column">
              <a routerLink="/stores/{{data.playExecution?.storeId}}" class="clickable">{{data.playExecution?.store?.name}} </a>
              <div class="search-result-subtext">{{data.playExecution?.robot?.name}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="play">
          <mat-header-cell *matHeaderCellDef> Play</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxLayoutAlign="center center">
                <mat-icon color="primary" [svgIcon]="'insight_marty'"></mat-icon>
              </div>
              <div fxLayout="column">
                <a routerLink="/stores/{{data.playExecution?.storeId}}/insight/play-executions/{{data.playExecution?.id}}"
                   class="clickable">{{data.playExecution?.play?.name}}</a>
                <div matTooltip="{{getMainDateTimeString(data.playExecution?.startTime, data.playExecution?.store)}}"
                     class="search-result-subtext">{{data.playExecution?.startTime | date:'short'}}</div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="aisle">
          <mat-header-cell *matHeaderCellDef> Aisle</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div *ngIf="getScanEvent(data.playExecution, data.scanEventId) as scanEvent" fxLayout="column">
              <a
                routerLink="/stores/{{data.playExecution?.storeId}}/insight/play-executions/{{data.playExecution?.id}}/scan-events/{{data.scanEventId}}"
                class="clickable">{{scanEvent.description}} </a>
              <div matTooltip="{{getMainDateTimeString(scanEvent.startedAt, scanEvent.playExecution?.store)}}"
                   class="search-result-subtext">{{scanEvent.startedAt | date:'short'}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef> Details</mat-header-cell>
          <mat-cell *matCellDef="let scanEvent">
            <div fxLayoutGap="5px" fxLayoutAlign="start center">
              <mat-icon
                [ngClass]="scanEvent.playExecution?.playOptions?.play_outputs?.includes('hole_oos') ? 'hole_oos' : 'issue-disabled'"
                [svgIcon]="'hole_oos_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="scanEvent.playExecution?.playOptions?.play_outputs?.includes('eOOS') ? 'eOOS' : 'issue-disabled'"
                [svgIcon]="'eOOS_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="scanEvent.playExecution?.playOptions?.play_outputs?.includes('price_mismatch') ? 'price_mismatch' : 'issue-disabled'"
                [svgIcon]="'price_mismatch_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="scanEvent.playExecution?.playOptions?.play_outputs?.includes('sale_price_mismatch') ? 'sale_price_mismatch' : 'issue-disabled'"
                [svgIcon]="'sale_price_mismatch_alt'">
              </mat-icon>
              <mat-icon
                [ngClass]="scanEvent.playExecution?.playOptions?.play_outputs?.includes('top_stock') ? 'top_stock' : 'issue-disabled'"
                [svgIcon]="'top_stock_alt'">
              </mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="scanEventDataTable?.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: scanEventDataTable?.displayedColumns"></mat-row>
      </mat-table>
    </mat-tab>

    <mat-tab label="Capture Event">
      <form *ngIf="captureEventForm" [formGroup]="captureEventIdFormGroup" (ngSubmit)="searchByCaptureId()">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="search-controls">
          <bt-form-field fxFlex="300px">
            <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
              <mat-label>Capture Event ID</mat-label>
              <input min="0" type="number" formControlName="capture_event_id" matInput/>
            </mat-form-field>
          </bt-form-field>
          <button mat-flat-button color="primary" type="submit" [disabled]="captureEventIdFormGroup.value?.capture_event_id == null">Search
          </button>
          <span *ngIf="captureEventNoResults" class="no-results">No results found.</span>
        </div>
      </form>
      <mat-table #table [dataSource]="captureEventDataTable">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <a routerLink="/stores/{{data.playExecution.storeId}}/inspect/play-executions/{{data.playExecution.id}}"
               [queryParams]="{'capture-event-id': data.captureEventId }"
               class="clickable">{{data.captureEventId}} </a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="play">
          <mat-header-cell *matHeaderCellDef> Play</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div fxLayout="row" fxLayoutGap="10px">
              <div fxLayoutAlign="center center">
                <mat-icon color="primary" [svgIcon]="'inspect_marty'"></mat-icon>
              </div>
              <div fxLayout="column">
                <a routerLink=""
                   [queryParams]="{'capture-event-id': data.captureEventId }"
                   (click)="onCaptureEventClick(data.captureEventId)"
                   class="clickable">{{data.playExecution.play?.name}}</a>
                <div matTooltip="{{getMainDateTimeString(data.playExecution.startTime, data.playExecution.store)}}"
                     class="search-result-subtext">{{data.playExecution.startTime | date:'short'}}</div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="store">
          <mat-header-cell *matHeaderCellDef> Store</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div fxLayout="column">
              <a routerLink="/stores/{{data.playExecution?.storeId}}" class="clickable">{{data.playExecution?.store?.name}} </a>
              <div class="search-result-subtext">{{data.playExecution?.robot?.name}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Details</mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div *ngIf="getCaptureEvent(data.playExecution, data.captureEventId) as captureEvent" fxLayout="row" fxLayoutGap="10px">
              <div *ngIf="captureEvent.status == 'urgent'" mat-tooltip="Urgent" fxLayoutAlign="center center">
                <mat-icon color="warn">warning</mat-icon>
              </div>
              <div *ngIf="captureEvent.status == 'hazardOnly'" mat-tooltip="Hazard only" fxLayoutAlign="center center">
                <mat-icon class="hazard-only-icon">warning</mat-icon>
              </div>
              <div>
                {{captureEvent.getReviewDetails().join(', ')}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let data">
            <div *ngIf="getCaptureEvent(data.playExecution, data.captureEventId) as captureEvent" fxLayout="column">
              <div>{{captureEvent.zoneDescription}}</div>
              <div matTooltip="{{getMainDateTimeString(captureEvent.capturedAt, data.playExecution.store)}}"
                   class="search-result-subtext">{{captureEvent.capturedAt | date:'short'}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="captureEventDataTable?.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: captureEventDataTable?.displayedColumns"></mat-row>
      </mat-table>
    </mat-tab>
  </mat-tab-group>
</div>


