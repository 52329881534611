<div class="viewport-thumbnails" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px" fxFlexFill>
  <div class="viewport-container" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
    <div fxLayoutAlign="center">
      <div
        class="{{imageSelection?.selected[0] === viewportThumbLeftComponent ? 'viewport-thumbnail-selected' : 'viewport-thumbnail'}}">
      </div>
    </div>
    <bt-viewport #viewportThumbLeft [clickToZoom]="false"
                 (click)="viewportThumbnailClicked(viewportThumbLeft)"
                 fxFlexFill>
    </bt-viewport>
  </div>

  <div class="viewport-container" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
    <div fxLayoutAlign="center">
      <div
        class="{{imageSelection?.selected[0]?.imageUrl != null ? (imageSelection?.selected[0] === viewportThumbCenterComponent ? 'viewport-thumbnail-selected' : 'viewport-thumbnail') : 'viewport-thumbnail'}}">
      </div>
    </div>
    <bt-viewport #viewportThumbCenter [clickToZoom]="false"
                 (click)="viewportThumbnailClicked(viewportThumbCenter)"
                 fxFlexFill>
    </bt-viewport>
  </div>

  <div class="viewport-container" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
    <div fxLayoutAlign="center">
      <div
        class="{{imageSelection?.selected[0] === viewportThumbRightComponent ? 'viewport-thumbnail-selected' : 'viewport-thumbnail'}}">
      </div>
    </div>
    <bt-viewport #viewportThumbRight [clickToZoom]="false"
                 (click)="viewportThumbnailClicked(viewportThumbRight)"
                 fxFlexFill>
    </bt-viewport>
  </div>
</div>
