<div [hidden]="status && status != 'realized'" style="width: 100%; height: 100%" fxLayout="column">
  <div #viewport class='viewport'
       [ngStyle]="{'height': 'calc(100% - ' + (brightnessControlVisible === true ? 60 : 0) + 'px)'}"
       (mousemove)="mouseMove($event)" (mousedown)="mouseDown()">
    @if (imageUrl) {
      <div #viewportImage class='viewportImage'
           [style.background-image]="imageUrl ? 'url(' + imageUrl + ')' : ''"
           [style.transform]="'scale(' + scaleFactor + ')'"
           [style.transform-origin]="transformOrigin"
           [style.filter]=filter>
      </div>
    }
    @if (viewportImageUrl) {
      <div class='viewportImage'
           [style.background-image]="viewportImageUrl ? 'url(' + viewportImageUrl + ')' : ''"
           [style.transform]="'scale(' + scaleFactor + ')'"
           [style.transform-origin]="transformOrigin">
      </div>
    }
  </div>
  <div *ngIf="brightnessControlVisible && imageUrl" fxLayout="row" fxLayoutAlign="center center"
       class="brightness-div">
    <mat-icon color="primary">brightness_low</mat-icon>
    <mat-slider #brightnessSlider color="primary"
                [disabled]="false"
                [max]="brightnessMax"
                [min]="brightnessMin"
                [step]="10">
      <input matSliderThumb [(ngModel)]="brightness" (valueChange)="setBrightness($event)"/>
    </mat-slider>
    <mat-icon color="primary">brightness_high</mat-icon>
  </div>
</div>


