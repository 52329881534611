import { catchError, filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ScanEventIssueInsightReview } from '../models/scan-event-issue-insight-review';
import { AbstractInsightReview } from '../models/abstract-insight-review';
import { ScanEventIssue } from '../models/scan-event-issue';

@Injectable({
  providedIn: 'root'
})
export class InsightReviewService extends ApiService {
  apiName = 'insight';

  getStats(params?: any): Observable<any> {
    return this.http.get(this.url('/insight/stats_by_org'), { params: params }).pipe(
      filter(response => response != null),
      map(response => response));
  }

  getStatsByOrg(params?: any): Observable<any> {
    return this.http.get(this.url('/insight/stats_by_org'), { params: ApiService.params(params) }).pipe(
      filter(response => response != null),
      map(response => response));
  }

  stats(params?: any): Observable<any> {
    return this.cable().channel('InsightReviewQueueSizeChannel', params).received().pipe(map(response => JSON.parse(response)));
  }

  reviewCreated(scanEventId: number): Observable<ScanEventIssueInsightReview> {
    return this.channel(ScanEventIssueInsightReview, 'ScanEventIssueReviewCreatedChannel', { scan_event_id: scanEventId }).pipe(
      tap(() => this.deleteCachedRequests(`scan_event_issue_reviews`)));
  }

  reviewUpdated(scanEventId: number): Observable<ScanEventIssueInsightReview> {
    return this.channel(ScanEventIssueInsightReview, 'ScanEventIssueReviewUpdatedChannel', { scan_event_id: scanEventId }).pipe(
      tap(() => this.deleteCachedRequests(`scan_event_issue_reviews`)));
  }

  updateReview(review: AbstractInsightReview): Observable<AbstractInsightReview> {
    return this.update(review, `/scan_event_issue_reviews/${review.id}`);
  }

  getNextScanEventIssueForReview(issueType?: string, params?: any): Observable<ScanEventIssue> {
    if (issueType) {
      return this.patchGet(ScanEventIssue, `/scan_event_issues/next_task?type=${issueType}`, params);
    }
    return this.patchGet(ScanEventIssue, `/scan_event_issues/next_task`, params);
  }

  /**
   * Fetch Reviews based on params
   */
  getScanEventIssueReviews(params?: any): Observable<ScanEventIssueInsightReview[]> {
    return this.list(ScanEventIssueInsightReview, '/scan_event_issue_reviews', params, 0);
  }

  /**
   * Fetch the latest Reviews for list of issues
   */
  getLatestReviewsForScanEventIssues(issues: ScanEventIssue[]): Observable<ScanEventIssueInsightReview[]> {
    return this.http.post(this.url('scan_event_issue_reviews/review_list'), {
      most_recent: true,
      'scan_event_issue_id_in': issues.map(issue => issue.id)
    }).pipe(
      filter(response => response != null),
      map((response: any[]) => response.map(source => new ScanEventIssueInsightReview().deserialize(source))),
      catchError(e => this.handleError(e))
    );
  }
}
